import { AccessLevel, nonePermission } from '@app/authorization/accessLevel';
import BuiltInRole from '@app/authorization/builtInRole';
import CustomRole from '@app/authorization/customRole';
import type { Universe } from '@app/models';

export default class RoleBinding {
  id: string;

  userId: string;

  username: string;

  builtInRole: AccessLevel;

  customRoleId: string | undefined;

  constructor(obj: any) {
    this.id = obj.id;
    this.userId = obj.userId;
    this.username = obj.user.name;
    this.builtInRole = obj.builtInRole;
    this.customRoleId = obj.customRoleId;
  }

  getRole(universe: Universe): CustomRole | BuiltInRole {
    if (this.builtInRole !== nonePermission) {
      return new BuiltInRole(this.builtInRole);
    }
    const role = universe.getRole(this.customRoleId as string);
    if (role === undefined) {
      throw new Error('Could not retrieve role for universe');
    }
    return role;
  }
}
